
  import { defineComponent, reactive, toRefs, onBeforeMount } from 'vue';
  import { ElMessage } from 'element-plus';
  import { getUserSystem } from '@/api/userinfo';
  import member from '../../assets/image/member.png';
  import memberIcon from '../../assets/image/memberIcon.png';
  import money from '../../assets/image/money.png';
  import moneyIcon from '../../assets/image/moneyIcon.png';
  import contract from '../../assets/image/contract.png';
  import contractIcon from '../../assets/image/contractIcon.png';
  import dormitory from '../../assets/image/dormitory.png';
  import dormitoryIcon from '../../assets/image/dormitoryIcon.png';
  import settle from '../../assets/image/settle.png';
  import settleIcon from '../../assets/image/settleIcon.png';
  import tax from '../../assets/image/tax.png';
  import taxIcon from '../../assets/image/taxIcon.png';
  import panel from '../../assets/image/panel.png';
  import panelIcon from '../../assets/image/panelIcon.png';
  import insurance from '../../assets/image/insurance.png';
  import insuranceIcon from '../../assets/image/insuranceIcon.png';

  // 定义 CardItem 的接口类型
  interface CardItem {
    name: string;
    code: string;
    linearColorActive: string;
    baseImg: string;
    icon: string;
    online: boolean;
    url?: string;
    overBg?: boolean;
  }

  // 定义卡片的静态数据
  const cardPicture: CardItem[] = [
    {
      name: '会员管理',
      code: 'shanks-manage-backend',
      linearColorActive: 'memberBorder',
      baseImg: member,
      icon: memberIcon,
      online: true,
    },
    {
      name: '发薪管理',
      code: 'tezzolo-finance-manager',
      linearColorActive: 'salaryBorder',
      baseImg: money,
      icon: moneyIcon,
      online: true,
    },
    {
      name: '电子合同',
      code: 'shanks-contract-backend',
      linearColorActive: 'contractBorder',
      baseImg: contract,
      icon: contractIcon,
      online: true,
    },
    {
      name: '宿舍管理',
      code: 'shanks-dormitory-backend',
      linearColorActive: 'dormitoryBorder',
      baseImg: dormitory,
      icon: dormitoryIcon,
      online: true,
    },
    {
      name: '结算管理',
      code: 'shanks-settle-backend',
      linearColorActive: 'settleBorder',
      baseImg: settle,
      icon: settleIcon,
      online: false,
    },
    {
      name: '个税管理',
      code: 'shanks-tax-backend',
      linearColorActive: 'taxBorder',
      baseImg: tax,
      icon: taxIcon,
      online: false,
    },
    {
      name: '数据看板',
      code: 'shanks-panel-backend',
      linearColorActive: 'panelBorder',
      baseImg: panel,
      icon: panelIcon,
      online: false,
    },
    {
      name: '商保管理',
      code: 'shanks-insurance-backend',
      linearColorActive: 'insuranceBorder',
      baseImg: insurance,
      icon: insuranceIcon,
      online: false,
    },
  ];

  // 定义组件
  export default defineComponent({
    setup() {
      // 初始化状态
      const state = reactive({
        cardData: [] as CardItem[],
      });

      // 切换系统
      const changeSystem = (item: CardItem, index: number): void => {
        if (!item.online) {
          ElMessage.info('即将上线，敬请期待！');
          return;
        }
        if (item.url) window.open(item.url);
      };

      // 获取系统数据并合并到 cardData
      const getSystemData = async () => {
        try {
          const res = await getUserSystem();
          const systemList: CardItem[] = res.data;
          state.cardData = systemList.reduce((acc: CardItem[], item) => {
            const matchingCard = cardPicture.find((card) => card.code === item.code);
            if (matchingCard) {
              acc.push({ ...matchingCard, ...item });
            }
            return acc;
          }, []);
        } catch (error) {
          console.error('Failed to fetch system data:', error);
        }
      };

      // 鼠标悬停效果
      const onMouseover = (index: number) => {
        state.cardData = state.cardData.map((item, i) => ({ ...item, overBg: i === index }));
      };

      // 鼠标离开效果
      const onmouseleave = () => {
        state.cardData = state.cardData.map((item) => ({ ...item, overBg: false }));
      };

      // 初始化数据
      onBeforeMount(() => {
        getSystemData();
      });

      return {
        ...toRefs(state),
        changeSystem,
        onMouseover,
        onmouseleave,
      };
    },
  });
